import React from "react";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

export interface confirmDialogProps {
    props: {
        open: boolean,
        handleModal: React.Dispatch<React.SetStateAction<boolean>>,
        onCancel: any,
        onExecution: any,
        onCancelRegist: any,
        mode: string,
        body: string,
        confirmCancel: string,
        confirmOk: string,
        confirmCancelRegist: string,
    }
}

const ConfirmDialog: React.FC<confirmDialogProps> = ({ props }) => {
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        props.onCancel()
    };

    const handleOk = () => {
        props.onExecution()
    };

    const handleCloseRegist = () => {
        props.onCancelRegist()
    };

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open])

    const displayConfirmCancel = () => {
        return props.confirmCancel !== "" ? "block" : "none"
    }

    const displayConfirmCancelRegist = () => {
        return props.confirmCancelRegist !== "" ? "block" : "none"
    }

    const displayConfirmOk = () => {
        return props.confirmOk !== "" ? "block" : "none"
    }

    return (
        <div>
            <Dialog
                open={open}
                keepMounted
                // onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <Box sx={{ margin: ' 20px' }}>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description" sx={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>{props.body}</DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'space-around' }}>
                        {
                            props.mode === "back" &&
                            <Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <Button variant="contained" color="success" size="small" sx={{ margin: '10px', display: displayConfirmCancel() }} onClick={handleClose}>{props.confirmCancel}</Button>
                                    <Button variant="outlined" color="error" size="small" sx={{ margin: '10px', display: displayConfirmCancelRegist() }} onClick={handleCloseRegist}>{props.confirmCancelRegist}</Button>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button variant="contained" color="error" size="small" sx={{ margin: '10px', display: displayConfirmOk() }} onClick={handleOk}>{props.confirmOk}</Button>
                                </Box>
                            </Box>
                        }
                        {
                            props.mode =="regist" &&
                            <Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <Button variant="outlined" color="error" size="small" sx={{ margin: '10px', display: displayConfirmCancel() }} onClick={handleClose}>{props.confirmCancel}</Button>
                                    <Button variant="contained" color="success" size="small" sx={{ margin: '10px', display: displayConfirmCancelRegist() }} onClick={handleCloseRegist}>{props.confirmCancelRegist}</Button>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button variant="contained" color="success" size="small" sx={{ margin: '10px', display: displayConfirmOk() }} onClick={handleOk}>{props.confirmOk}</Button>
                                </Box>
                            </Box>
                        }
                        {
                            (!["back", "regist"].includes(props.mode)) &&
                            <Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <Button variant="outlined" color="error" size="small" sx={{ margin: '10px', display: displayConfirmCancel() }} onClick={handleClose}>{props.confirmCancel}</Button>
                                    <Button variant="contained" color="success" size="small" sx={{ margin: '10px', display: displayConfirmCancelRegist() }} onClick={handleCloseRegist}>{props.confirmCancelRegist}</Button>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button variant="contained" color="success" size="small" sx={{ margin: '10px', display: displayConfirmOk() }} onClick={handleOk}>{props.confirmOk}</Button>
                                </Box>
                            </Box>
                        }
                    </DialogActions>
                </Box>
            </Dialog>
        </div>
    );

}

export default ConfirmDialog;


