import * as React from 'react';

/**
 * pull to refreshの無効化
 */
export const hasScroll = () => {
    let passive = true
    document.addEventListener('touchmove', function(evt) {
            if ([
                "/TrafficVolume",
                "/TrafficVolume/select",
                "/Photo",
            ].includes(window.location.pathname)) {
                passive = false
            }
            if (passive) {
                evt.preventDefault();
            }
        }, {
        passive: false
    });
}



export interface scrollProps {
    props:{
    }
  }

const Scroll:React.FC<scrollProps> = ({props}) => {


    return (
        <></>
    );
        
}


export default Scroll;
