import * as React from 'react';
import { apiRequest } from '../../../api/ApiCall'
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { useUtilityContext } from '../../../utility-provider';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import {
    Box,
    Typography,
    Paper,
    Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';

import useSound from 'use-sound';
import move_2 from '../../../sound/move_2.mp3';
import decision_2 from '../../../sound/decision_2.mp3';
import beep_2 from '../../../sound/beep_2.mp3';
import alarm_1 from '../../../sound/alarm_1.mp3';
import { setImpactData } from '../../../features/impactDataSlice';
import ConfirmDialog from './ConfirmDialog';
import RefreshIcon from '@mui/icons-material/Refresh';
import Reset from '../../common/Reset'
import { hasScroll } from "../../common/Scroll";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    border: '2px solid #ffa500',
    display: 'grid',
    placeItems: 'center',
    width: '120px',
    color: '#FFF',
    backgroundColor: '#ffa500',
  }));

export interface resultProps {
    props:{
    }
}

const COMMON = "#00FF00"
const UNCOMMON = "#FFFFFF"
const UNRELATED = "#CCCCCC"

const defaultCountList = () => {
    return [
        [
            {type: 'blank'},
            {type: 'landscape-data-top', row: [
                [
                    {type: 'car', direction: 'top_left', key: 'top_car_left', val: 0, bgColor: UNCOMMON, locked: false },
                    {type: 'car', direction: 'top_bottom', key: 'top_car_bottom', val: 0, bgColor: UNCOMMON, locked: false },
                    {type: 'car', direction: 'top_right', key: 'top_car_right', val: 0, bgColor: UNCOMMON, locked: false },
                ],
                [
                    {type: 'people', direction: 'top_left', key: 'top_people_left', val: 0, bgColor: UNCOMMON, locked: false },
                    {type: 'people', direction: 'top_bottom', key: 'top_people_bottom', val: 0, bgColor: UNCOMMON, locked: false },
                    {type: 'people', direction: 'top_right', key: 'top_people_right', val: 0, bgColor: UNCOMMON, locked: false },
                ]
            ]},
            {type: 'common_uncommon', car: 'true', people: 'true' },
        ],
        [
            {type: 'portrait-data-left', row: [
                [
                    {type: 'car', direction: 'left_top', key: 'left_car_top',val: 0, bgColor: UNCOMMON, locked: false },
                    {type: 'people', direction: 'left_top', key: 'left_people_top',val: 0, bgColor: UNCOMMON, locked: false },
                ],
                [
                    {type: 'car', direction: 'left_right', key: 'left_car_right', val: 0, bgColor: UNCOMMON, locked: false },
                    {type: 'people', direction: 'left_right', key: 'left_people_right', val: 0, bgColor: UNCOMMON, locked: false },
                ],
                [
                    {type: 'car', direction: 'left_bottom', key: 'left_car_bottom', val: 0, bgColor: UNCOMMON, locked: false },
                    {type: 'people', direction: 'left_bottom', key: 'left_people_bottom', val: 0, bgColor: UNCOMMON, locked: false },
                ],
            ]},
            {type: 'intersection'},
            {type: 'portrait-data-right', row: [
                [
                    {type: 'people', direction: 'right_top', key: 'right_people_top', val: 0, bgColor: UNCOMMON, locked: false },
                    {type: 'car', direction: 'right_top', key: 'right_car_top', val: 0, bgColor: UNCOMMON, locked: false }
                ],
                [
                    {type: 'people', direction: 'right_left', key: 'right_people_left', val: 0, bgColor: UNCOMMON, locked: false },
                    {type: 'car', direction: 'right_left', key: 'right_car_left', val: 0, bgColor: UNCOMMON, locked: false }
                ],
                [
                    {type: 'people', direction: 'right_bottom', key: 'right_people_bottom', val: 0, bgColor: UNCOMMON, locked: false },
                    {type: 'car', direction: 'right_bottom', key: 'right_car_bottom', val: 0, bgColor: UNCOMMON, locked: false }
                ]
            ]},
        ],
        [
            {type: 'color'},
            {type: 'landscape-data-bottom', row: [
                [
                    {type: 'people', direction: 'bottom_left', key: 'bottom_people_left', val: 0, bgColor: UNCOMMON, locked: false },
                    {type: 'people', direction: 'bottom_top', key: 'bottom_people_top', val: 0, bgColor: UNCOMMON, locked: false },
                    {type: 'people', direction: 'bottom_right', key: 'bottom_people_right', val: 0, bgColor: UNCOMMON, locked: false },
                ],
                [
                    {type: 'car', direction: 'bottom_left', key: 'bottom_car_left', val: 0, bgColor: UNCOMMON, locked: false },
                    {type: 'car', direction: 'bottom_top', key: 'bottom_car_top', val: 0, bgColor: UNCOMMON, locked: false },
                    {type: 'car', direction: 'bottom_right', key: 'bottom_car_right', val: 0, bgColor: UNCOMMON, locked: false },
                ]
            ]},
            {type: 'icon'},
        ]
    ]
}

const carCountList = () => {
    return [
        [
            {type: 'blank'},
            {type: 'landscape-data-top', row: [
                [
                    {type: 'car', direction: 'top_left', key: 'top_car_left', val: 0, bgColor: UNCOMMON, locked: false },
                    {type: 'car', direction: 'top_bottom', key: 'top_car_bottom', val: 0, bgColor: UNCOMMON, locked: false },
                    {type: 'car', direction: 'top_right', key: 'top_car_right', val: 0, bgColor: UNCOMMON, locked: false },
                ],
            ]},
            {type: 'common_uncommon', car: 'true', people: 'false' },
        ],
        [
            {type: 'portrait-data-left', row: [
                [
                    {type: 'car', direction: 'left_top', key: 'left_car_top',val: 0, bgColor: UNCOMMON, locked: false },
                ],
                [
                    {type: 'car', direction: 'left_right', key: 'left_car_right', val: 0, bgColor: UNCOMMON, locked: false },
                ],
                [
                    {type: 'car', direction: 'left_bottom', key: 'left_car_bottom', val: 0, bgColor: UNCOMMON, locked: false },
                ],
            ]},
            {type: 'intersection'},
            {type: 'portrait-data-right', row: [
                [
                    {type: 'car', direction: 'right_top', key: 'right_car_top', val: 0, bgColor: UNCOMMON, locked: false },
                ],
                [
                    {type: 'car', direction: 'right_left', key: 'right_car_left', val: 0, bgColor: UNCOMMON, locked: false },
                ],
                [
                    {type: 'car', direction: 'right_bottom', key: 'right_car_bottom', val: 0, bgColor: UNCOMMON, locked: false },
                ]
            ]},
        ],
        [
            {type: 'color'},
            {type: 'landscape-data-bottom', row: [
                [
                    {type: 'car', direction: 'bottom_left', key: 'bottom_car_left', val: 0, bgColor: UNCOMMON, locked: false },
                    {type: 'car', direction: 'bottom_top', key: 'bottom_car_top', val: 0, bgColor: UNCOMMON, locked: false },
                    {type: 'car', direction: 'bottom_right', key: 'bottom_car_right', val: 0, bgColor: UNCOMMON, locked: false },
                ]
            ]},
            {type: 'icon'},
        ]
    ]
}

const peopleCountList = () => {
    return [
        [
            {type: 'blank'},
            {type: 'landscape-data-top', row: [
                [
                    {type: 'people', direction: 'top_left', key: 'top_people_left', val: 0, bgColor: UNCOMMON, locked: false },
                    {type: 'people', direction: 'top_bottom', key: 'top_people_bottom', val: 0, bgColor: UNCOMMON, locked: false },
                    {type: 'people', direction: 'top_right', key: 'top_people_right', val: 0, bgColor: UNCOMMON, locked: false },
                ]
            ]},
            {type: 'common_uncommon', car: 'false', people: 'true' },
        ],
        [
            {type: 'portrait-data-left', row: [
                [
                    {type: 'people', direction: 'left_top', key: 'left_people_top',val: 0, bgColor: UNCOMMON, locked: false },
                ],
                [
                    {type: 'people', direction: 'left_right', key: 'left_people_right', val: 0, bgColor: UNCOMMON, locked: false },
                ],
                [
                    {type: 'people', direction: 'left_bottom', key: 'left_people_bottom', val: 0, bgColor: UNCOMMON, locked: false },
                ],
            ]},
            {type: 'intersection'},
            {type: 'portrait-data-right', row: [
                [
                    {type: 'people', direction: 'right_top', key: 'right_people_top', val: 0, bgColor: UNCOMMON, locked: false }
                ],
                [
                    {type: 'people', direction: 'right_left', key: 'right_people_left', val: 0, bgColor: UNCOMMON, locked: false }
                ],
                [
                    {type: 'people', direction: 'right_bottom', key: 'right_people_bottom', val: 0, bgColor: UNCOMMON, locked: false }
                ]
            ]},
        ],
        [
            {type: 'color'},
            {type: 'landscape-data-bottom', row: [
                [
                    {type: 'people', direction: 'bottom_left', key: 'bottom_people_left', val: 0, bgColor: UNCOMMON, locked: false },
                    {type: 'people', direction: 'bottom_top', key: 'bottom_people_top', val: 0, bgColor: UNCOMMON, locked: false },
                    {type: 'people', direction: 'bottom_right', key: 'bottom_people_right', val: 0, bgColor: UNCOMMON, locked: false },
                ],
            ]},
            {type: 'icon'},
        ]
    ]
}

const countDataType = {
    top_car_left: 0,
    top_car_bottom: 0,
    top_car_right: 0,
    top_people_left: 0,
    top_people_bottom: 0,
    top_people_right: 0,
    left_car_top: 0,
    left_car_bottom: 0,
    left_car_right: 0,
    left_people_top: 0,
    left_people_bottom: 0,
    left_people_right: 0,
    bottom_car_top: 0,
    bottom_car_left: 0,
    bottom_car_right: 0,
    bottom_people_top: 0,
    bottom_people_left: 0,
    bottom_people_right: 0,
    right_car_top: 0,
    right_car_left: 0,
    right_car_bottom: 0,
    right_people_top: 0,
    right_people_left: 0,
    right_people_bottom: 0,
}

const Impact:React.FC<resultProps> = ({props}) => {
    React.useEffect(() => {
        hasScroll()
    }, [])

    const [date, setDate] = React.useState("");
    const [countList, setCountList] = React.useState<any>([]);
    // // 初期状態保持用
    // const [initCountList, setInitCountList] = React.useState<any>([]);
    const [countData, setCountData] = React.useState<any>(JSON.parse(JSON.stringify(countDataType)));
    const [checked, setChecked] = React.useState(true);
    const [counter, setCounter] = React.useState(false);
    const location = useLocation();

    const idList = useAppSelector((state) => state.impactData.list);
    const dispatch = useAppDispatch();

    const [countTimer, setCountTimer] = React.useState<number>(idList.countTime * 60)
    const [counterStart, setCounterStart] = React.useState(false)

    const utilityCtx = useUtilityContext();

    const [open, setOpen] = React.useState<boolean>(false);
    const [confirmMode, setConfirmMode] = React.useState<string>("");
    const [confirmBody, setConfirmBody] = React.useState<string>("");
    const [confirmCancelButton, setConfirmCancelButton] = React.useState<string>("");
    const [confirmOkButton, setConfirmOkButton] = React.useState<string>("");
    const [confirmCancelRegistButton, setConfirmCancelRegistButton] = React.useState<string>("");
    const backRef = React.useRef<HTMLDivElement>(null);
    const [returnMultiPoly, setReturnMultiPoly] = React.useState<any>()
    // 
    const [sound_move_2] = useSound(move_2);
    const [sound_decision_2] = useSound(decision_2);
    const [sound_beep_2] = useSound(beep_2);
    const [sound_alarm_1] = useSound(alarm_1);


    // boxSize
    const [boxWidth, setBoxWidth] = React.useState('46px')
    const [portraitBoxWidth, setPortraitBoxWidth] = React.useState('46px')
    const [intersectionWidth, setIntersectionWidth] = React.useState('170px')

    const [openReset, setOpenReset] = React.useState<boolean>(false)
    // 任意計測用
    const [arbitraryMeasurement, setArbitraryMeasurement] = React.useState<boolean>(false)
    const [arbitraryCount, setArbitraryCount] = React.useState<number>(0)
    // カウント用
    const [carCommonCount, setCarCommonCount] = React.useState<number>(0);
    const [carUncommonCount, setCarUncommonCount] = React.useState<number>(0);
    const [peopleCommonCount, setPeopleCommonCount] = React.useState<number>(0);
    const [peopleUncommonCount, setPeopleUncommonCount] = React.useState<number>(0);

    React.useEffect(() => {
        const date = new Date();
        const dateString = date.getFullYear().toString().substring(2, 4)
            +'/'+ (date.getMonth() + 1).toString().padStart(2, "0")
            +'/'+ date.getDate().toString().padStart(2, "0");
        setDate(dateString)

        initDataCountList()
    }, []);

    // リセット
    const registReset = () => {
        setCounterStart(false)
        setCountTimer(idList.countTime * 60)
        setArbitraryMeasurement(false)
        setArbitraryCount(0)
        setCarCommonCount(0)
        setCarUncommonCount(0)
        setPeopleCommonCount(0)
        setPeopleUncommonCount(0)
        setOpenReset(false)
        initDataCountList()
    }

    const initDataCountList = () => {
        if (idList.counter_type.toString() === '0') {
            setCountList(carCountList)
            setBoxWidth('40px')
            setPortraitBoxWidth('40px')
            setIntersectionWidth('150px')
        } else if (idList.counter_type.toString() === '1') {
            setCountList(defaultCountList)
            setBoxWidth('45px')
            setPortraitBoxWidth('45px')
            setIntersectionWidth('150px')
        } else if (idList.counter_type.toString() === '2') {
            setCountList(peopleCountList)
            setBoxWidth('40px')
            setPortraitBoxWidth('40px')
            setIntersectionWidth('150px')
        }
    }

    React.useEffect(() => {
        setReturnMultiPoly(idList.multiPoly)
    }, [idList.multiPoly])

    // 初期設定
    React.useEffect(() => {
        countList.map((rowData: any) => {
            rowData.map((line: any) => {
                if (line.type.includes("data")) {
                    line.row.map((rowLine: any) => {
                        rowLine.map((row: any) => {
                            checkBgColor(row.key, row)
                        })
                    })
                }
            })
        })
        setCounter(!counter)
    }, [countList])

    // クリック処理
    const setCount = (key: string, data: string) => {
        countList?.map((rowData: any) => {
            rowData.map((line: any) => {
                if (line.type.includes("data")) {
                    line.row.map((rowLine: any) => {
                        rowLine.map((row: any) => {
                            if (counterStart) {
                                if (!row.locked && row.key === key) {
                                    let unset = false
                                    if (checked) {
                                        row.val = row.val + 1
                                        if (key.includes("car")) {
                                            sound_move_2()
                                        } else {
                                            sound_decision_2()
                                        }
                                    } else if (row.val > 0) {
                                        row.val = row.val - 1
                                        unset = true
                                        sound_beep_2();
                                    }
                                    countData[key] = row.val
                                    // 共通、非共通計算
                                    if ([COMMON].includes(row.bgColor)) {
                                        if (key.includes("car")) {
                                            if (checked) {
                                                setCarCommonCount(carCommonCount + 1)
                                            } else if (unset) {
                                                setCarCommonCount(carCommonCount - 1)
                                            }
                                        } else {
                                            if (checked) {
                                                setPeopleCommonCount(peopleCommonCount + 1)
                                            } else if (unset) {
                                                setPeopleCommonCount(peopleCommonCount - 1)
                                            }
                                        }
                                    } else if ([UNCOMMON].includes(row.bgColor)) {
                                        if (key.includes("car")) {
                                            if (checked) {
                                                setCarUncommonCount(carUncommonCount + 1)
                                            } else if (unset) {
                                                setCarUncommonCount(carUncommonCount - 1)
                                            }
                                        } else {
                                            if (checked) {
                                                setPeopleUncommonCount(peopleUncommonCount + 1)
                                            } else if (unset) {
                                                setPeopleUncommonCount(peopleUncommonCount - 1)
                                            }
                                        }
                                    }
                                }
                            } else {
                                if (!row.locked && row.key === key) {
                                    if (row.bgColor === COMMON) {
                                        row.bgColor = UNCOMMON
                                    } else if (row.bgColor === UNCOMMON) {
                                        row.bgColor = UNRELATED
                                    } else {
                                        row.bgColor = COMMON
                                    }
                                }
                            }
                        })
                    })
                }
            })
        })
        setCounter(!counter)
        setCountList(countList)

        return
    };

    const showCommonRatio = (isCarPeople: string) => {
        let raito = 0
        if (isCarPeople === "car") {
            raito = carCommonCount / (carCommonCount + carUncommonCount)
        } else {
            raito = peopleCommonCount / (peopleCommonCount + peopleUncommonCount)
        }

        return isNaN(raito) ? 0 + '%' : (Math.round(raito * 1000) / 10) + '%'
    }

    function handleChange() {
        setChecked(!checked)
    }

    // 計測カウントダウン
    React.useEffect(() => {
        const countDownInterval = setInterval(() => {
            if (counterStart) {
                if (countTimer === 0) {
                    clearInterval(countDownInterval)
                    showRegistConfirm()
                } else if (countTimer && countTimer > 0) {
                    setCountTimer(countTimer - 1)
                }
            } else {
                clearInterval(countDownInterval)
            }
        }, 1000)
        return () => {
            clearInterval(countDownInterval)
        }
    }, [countTimer, counterStart])

    // 任意計測
    React.useEffect(() => {
        const countDownInterval = setInterval(() => {
            if (arbitraryMeasurement) {
                setArbitraryCount(arbitraryCount + 1)
            } else {
                clearInterval(countDownInterval)
            }
        }, 1000)
        return () => {
            clearInterval(countDownInterval)
        }
    }, [arbitraryCount, arbitraryMeasurement])

    const setCountDate = () => {
        const date = new Date()
        let hours = (date.getUTCHours() + 9)
        let minutes = date.getUTCMinutes() - idList.countTime
        if (minutes < 0) {
            hours = hours - 1
            minutes = (date.getUTCMinutes() + 60) - idList.countTime
        }

        return date.getUTCFullYear() + "-" +
            (date.getUTCMonth() + 1).toString().padStart(2, "0") + "-" +
            date.getUTCDate().toString().padStart(2, "0") + " " +
            hours.toString().padStart(2, "0") + ":" +
            minutes.toString().padStart(2, "0") + ":" +
            date.getUTCSeconds().toString().padStart(2, "0") + "." +
            date.getUTCMilliseconds()
    }


    const checkCounterStart = () => {
        if (counterStart === false) {
            setCounterStart(true)
        } else {
            clickBack()
        }
    }

    const checkBgColor = (key: any, data: any) => {
        switch (idList.intersection_type.toString()) {
            case '1':
                // 二叉路＿横
                if (
                    !data.direction.includes(intersection(4)) &&
                    !data.direction.includes(intersection(10))
                ) {
                    data.locked = true
                }
                break;
            case '2':
                // 二叉路＿縦
                if (
                    !data.direction.includes(intersection(1)) &&
                    !data.direction.includes(intersection(7))
                ) {
                    data.locked = true
                }
                break;
            case '3':
                // T左
                if (
                    !data.direction.includes(intersection(0)) &&
                    !data.direction.includes(intersection(1)) &&
                    !data.direction.includes(intersection(7)) &&
                    !data.direction.includes(intersection(8)) &&
                    !data.direction.includes(intersection(9)) &&
                    !data.direction.includes(intersection(11))
                ) {
                    data.locked = true
                }
                break;
            case '4':
                // T右
                if (
                    !data.direction.includes(intersection(1)) &&
                    !data.direction.includes(intersection(2)) &&
                    !data.direction.includes(intersection(3)) &&
                    !data.direction.includes(intersection(5)) &&
                    !data.direction.includes(intersection(6)) &&
                    !data.direction.includes(intersection(7))
                ) {
                    data.locked = true
                }
                break;
            case '5':
                // T下
                if (
                    !data.direction.includes(intersection(4)) &&
                    !data.direction.includes(intersection(5)) &&
                    !data.direction.includes(intersection(6)) &&
                    !data.direction.includes(intersection(8)) &&
                    !data.direction.includes(intersection(9)) &&
                    !data.direction.includes(intersection(10))
                ) {
                    data.locked = true
                }
                break;
            case '6':
                // T上
                if (
                    !data.direction.includes(intersection(0)) &&
                    !data.direction.includes(intersection(2)) &&
                    !data.direction.includes(intersection(3)) &&
                    !data.direction.includes(intersection(4)) &&
                    !data.direction.includes(intersection(10)) &&
                    !data.direction.includes(intersection(11))
                ) {
                    data.locked = true
                }
                break;
            case '7':
                // Y上
                if (
                    !data.direction.includes(intersection(0)) &&
                    !data.direction.includes(intersection(2)) &&
                    !data.direction.includes(intersection(3)) &&
                    !data.direction.includes(intersection(6)) &&
                    !data.direction.includes(intersection(8)) &&
                    !data.direction.includes(intersection(11))
                ) {
                    data.locked = true
                }
                break;
            case '8':
                // Y下
                if (
                    !data.direction.includes(intersection(0)) &&
                    !data.direction.includes(intersection(2)) &&
                    !data.direction.includes(intersection(5)) &&
                    !data.direction.includes(intersection(6)) &&
                    !data.direction.includes(intersection(8)) &&
                    !data.direction.includes(intersection(9))
                ) {
                    data.locked = true
                }
                break;
            case '9':
                // Y左
                if (
                    !data.direction.includes(intersection(0)) &&
                    !data.direction.includes(intersection(3)) &&
                    !data.direction.includes(intersection(5)) &&
                    !data.direction.includes(intersection(8)) &&
                    !data.direction.includes(intersection(9)) &&
                    !data.direction.includes(intersection(11))
                ) {
                    data.locked = true
                }
                break;
            case '10':
                // Y右
                if (
                    !data.direction.includes(intersection(2)) &&
                    !data.direction.includes(intersection(3)) &&
                    !data.direction.includes(intersection(5)) &&
                    !data.direction.includes(intersection(6)) &&
                    !data.direction.includes(intersection(9)) &&
                    !data.direction.includes(intersection(11)) 
                ) {
                    data.locked = true
                }
                break;
            case '11':
                // 中央分離帯＿横
                if (
                    !data.direction.includes(intersection(3)) &&
                    !data.direction.includes(intersection(5)) &&
                    !data.direction.includes(intersection(9)) &&
                    !data.direction.includes(intersection(11))
                ) {
                    data.locked = true
                }
                break;
            case '12':
                // 中央分離帯＿縦
                if (
                    !data.direction.includes(intersection(0)) &&
                    !data.direction.includes(intersection(2)) &&
                    !data.direction.includes(intersection(6)) &&
                    !data.direction.includes(intersection(8))
                ) {
                    data.locked = true
                }
                break;
        }

        data.bgColor = COMMON
        if (data.locked) {
            data.bgColor = UNRELATED
        }
    }

    // 交差点位置
    const intersection = (key: number) => {
        const intersectionType = [
            'top_left',
            'top_bottom',
            'top_right',
            'right_top',
            'right_left',
            'right_bottom',
            'bottom_right',
            'bottom_top',
            'bottom_left',
            'left_bottom',
            'left_right',
            'left_top',
        ]
        return intersectionType[key]
    }


    const settingBody = (type: string) => {
        let body = ""
        let car_total = 0
        let people_total = 0
        countList?.map((rowData: any) => {
            rowData.map((data: any) => {
                if (data.type.includes('data')) {
                    data.row.map((landData: any) => {
                        landData.map((line: any) => {
                            if (line.type.includes('car')) {
                                car_total += line.val
                            } else if (line.type.includes('people')) {
                                people_total += line.val
                            }    
                        })
                    })
                }
            })
        })
        switch (type) {
            case  'back':
            case  'regist':
                body = "現在の計測結果\n";
                if (idList.counter_type.toString() === '0') {
                    body += "車："+car_total
                } else if (idList.counter_type.toString() === '1') {
                    body += "車："+car_total+"\n人："+people_total
                } else if (idList.counter_type.toString() === '2') {
                    body += "人："+people_total
                }
                break;
            case 'cancelConfirm':
                body = "計測結果を保存せずに\n終了してもよろしいですか";
                break;
            case 'finishRegist':
                body = "保存が完了しました";
                break;
            case 'errorRegist':
                body = "保存に失敗しました";
                break;
        }

        setConfirmBody(body)
    }

    // 任意計測完了
    const finishiArbitrary = () => {
        sound_alarm_1()
        settingBody('regist')
        setConfirmCancelButton("キャンセル")
        setConfirmOkButton("")
        setConfirmCancelRegistButton("保存")
        setConfirmMode("finishiArbitrary")
        setOpen(true)
    }

    const clickBack = () => {
        settingBody('back')
        setConfirmCancelButton("再開")
        setConfirmOkButton("保存")
        setConfirmCancelRegistButton("中止")
        setConfirmMode("back")
        setCounterStart(false)
        setOpen(true)
    }

    const showRegistConfirm = () => {
        sound_alarm_1()
        settingBody('regist')
        setConfirmCancelButton("キャンセル")
        setConfirmOkButton("任意計測")
        setConfirmCancelRegistButton("保存")
        setConfirmMode("regist")
        setOpen(true)
    }

    const showCancelConfirm = () => {
        settingBody('cancelConfirm')
        setConfirmCancelButton("いいえ")
        setConfirmOkButton("")
        setConfirmCancelRegistButton("はい")
        setConfirmMode("cancelConfirm")
    }

    const finishRegist = () => {
        settingBody('finishRegist')
        setConfirmCancelButton("")
        setConfirmOkButton("閉じる")
        setConfirmCancelRegistButton("")
        setConfirmMode("finishRegist")
        setOpen(true)
    }

    const errorRegist = () => {
        settingBody('errorRegist')
        setConfirmCancelButton("")
        setConfirmOkButton("閉じる")
        setConfirmCancelRegistButton("")
        setConfirmMode("errorRegist")
        setOpen(true)
    }

    const confirmCancel = () => {
        if (confirmMode) {
            switch (confirmMode) {
                case 'back':
                    setCounterStart(true)
                    setOpen(false)
                    break;
                case 'finishiArbitrary':
                    setOpen(false)
                    break;
                case 'regist':
                    showCancelConfirm()
                    break;
                case 'cancelConfirm':
                    showRegistConfirm()
                    break;
            }
        }
    }

    const confirmRegist = () => {
        setOpen(false)
        switch (confirmMode) {
            case 'regist':
            case 'finishiArbitrary':
                reStart()
                break;
            case 'back':
                onRegist()
                break;
            case 'cancelConfirm':
            case 'finishRegist':
            case 'errorRegist':
                confirmCancelRegist()
                break;
        }
    }

    const reStart = () => {
        setCounterStart(true)
        setArbitraryMeasurement(true)
    }

    const confirmCancelRegist = () => {
        setOpen(false)
        if (backRef) {
            backRef.current?.click();
        }
    }

    const set_intersection = () => {
        const intersection_type_list = [
            "/image/impact/十字.png",  // 0
            "/image/impact/二叉路＿横.png",  // 1
            "/image/impact/二叉路＿縦.png",  // 2
            "/image/impact/T左.png",  // 3
            "/image/impact/T右.png",  // 4
            "/image/impact/T下.png",  // 5
            "/image/impact/T上.png",  // 6
            "/image/impact/Y上.png",  // 7
            "/image/impact/Y下.png",  // 8
            "/image/impact/Y左.png",  // 9
            "/image/impact/Y右.png",  // 10
            "/image/impact/中央分離帯＿横.png",  // 11
            "/image/impact/中央分離帯＿縦.png",  // 12
        ]
        return intersection_type_list[idList.intersection_type]
    }

    const set_icon = () => {
        const counter_type_list = [
            "/image/impact/impact_car.png",
            "/image/impact/impact_car_people.png",
            "/image/impact/impact_people.png"
        ]

        return counter_type_list[idList.counter_type]
    }

    
    const clickReset = () => {
        if (counterStart && !arbitraryMeasurement) {
            setOpenReset(true)
        }
    }

    const cancelReset = () => {
        setOpenReset(false)
    }

    const onRegist = () => {
        const params = {
            mode: "postRequest",
            endPoint: "/impactcounter/v1/regist",
            query: {
                uuid: idList.impactCounterData !== undefined 
                && idList.impactCounterData !== null 
                ? idList.impactCounterData.uuid.toString() 
                : uuidv4(),
                centerlat: idList.latlng.lat.toString(),
                centerlon: idList.latlng.lng.toString(),
                name: "",
                count_date: setCountDate(),
                count_time: idList.countTime.toString(),
                shape: idList.multiPoly,
                counter_type: idList.counter_type.toString(),
                intersection_type: idList.intersection_type.toString(),
                countList: {
                    top_car_left: countData['top_car_left'].toString(),
                    top_car_bottom: countData['top_car_bottom'].toString(),
                    top_car_right: countData['top_car_right'].toString(),
                    top_people_left: countData['top_people_left'].toString(),
                    top_people_bottom: countData['top_people_bottom'].toString(),
                    top_people_right: countData['top_people_right'].toString(),
                    left_car_top: countData['left_car_top'].toString(),
                    left_car_bottom: countData['left_car_bottom'].toString(),
                    left_car_right: countData['left_car_right'].toString(),
                    left_people_top: countData['left_people_top'].toString(),
                    left_people_bottom: countData['left_people_bottom'].toString(),
                    left_people_right: countData['left_people_right'].toString(),
                    bottom_car_top: countData['bottom_car_top'].toString(),
                    bottom_car_left: countData['bottom_car_left'].toString(),
                    bottom_car_right: countData['bottom_car_right'].toString(),
                    bottom_people_top: countData['bottom_people_top'].toString(),
                    bottom_people_left: countData['bottom_people_left'].toString(),
                    bottom_people_right: countData['bottom_people_right'].toString(),
                    right_car_top: countData['right_car_top'].toString(),
                    right_car_left: countData['right_car_left'].toString(),
                    right_car_bottom: countData['right_car_bottom'].toString(),
                    right_people_top: countData['right_people_top'].toString(),
                    right_people_left: countData['right_people_left'].toString(),
                    right_people_bottom: countData['right_people_bottom'].toString(),
                },
                latlng: idList.latlng,
            }
        };
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
        try {
            let countTime = idList.countTime * 60 - countTimer
            if (arbitraryMeasurement) {
                countTime = idList.countTime * 60 + countTimer + arbitraryCount
            } else {
                if (countTime < 0) {
                    countTime = idList.countTime * 60 + countTimer
                }    
            }
            params.query.count_time = Math.round(countTime / 60) > 0 ? Math.round(countTime / 60).toString() : "1"
            await apiRequest(params);
            if (utilityCtx.hideSpinner) {
                utilityCtx.hideSpinner();
            }
            dispatch(setImpactData({
                ...idList,
                countTime: 0,
                counter_type: 1,
                intersection_type: 0,
                multiPoly: undefined,
                impactCounterData: undefined
            }))
            setReturnMultiPoly(null)
            finishRegist()
        } catch (e) {
            console.log(e);
            if (utilityCtx.hideSpinner) {
                utilityCtx.hideSpinner();
            }
            errorRegist()
        }
        })();
    }

    return (
        <div style={{ width: "100%" }}>
            <ConfirmDialog 
                props={{
                    open:open,
                    handleModal:setOpen,
                    onCancel:confirmCancel,
                    onExecution:confirmRegist,
                    onCancelRegist:confirmCancelRegist,
                    mode:confirmMode,
                    body:confirmBody,
                    confirmCancel:confirmCancelButton,
                    confirmOk:confirmOkButton,
                    confirmCancelRegist:confirmCancelRegistButton,
                }}
            />
            <Reset
                props={{
                    open: openReset,
                    handleModal: setOpenReset,
                    onCancel:cancelReset,
                    onExecution:registReset,
                }}
            />
            <Link to="/Impact"><div ref={backRef}></div></Link>
            <Box sx={{ display: 'flex', justifyContent: 'space-around', margin: '10px 0' }}>
                <Box sx={{ display: 'flex' }}>
                    <Button
                        variant="outlined"
                        size="small"
                        sx={{margin: '0', minWidth: '30px', padding: '10px 0',
                        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
                        }}
                        onClick={clickBack}
                    >≪</Button><Typography sx={{ padding: '8px 0' }}>{date}</Typography>
                </Box>
                <Box>
                    {
                        !arbitraryMeasurement &&
                        <Item onClick={checkCounterStart}>
                            {counterStart === false
                            ? '計測開始'
                            : String(Math.floor(countTimer / 60).toString().padStart(2, "0")) + '：' + String(countTimer % 60).toString().padStart(2, "0")}
                        </Item>
                    }
                    {
                        arbitraryMeasurement &&
                        <Item onClick={finishiArbitrary}>完了</Item>
                    }
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <Box
                        sx={{ padding: '10px 4px 0 4px', marginRight: '4px', color: arbitraryMeasurement ? "#ccc" : 'red', border: '1px solid red', borderRadius: '4px' }}
                        ><RefreshIcon
                        onClick={clickReset}
                    ></RefreshIcon>
                    </Box>
                    <Box sx={{textAlign: 'right'}}>
                        <Button
                            onClick={handleChange}
                            variant="outlined"
                            sx={{
                                fontSize: '1.2rem',
                                padding: '4px 0',
                                backgroundColor: checked ? "#00CC5B" : "#D9D9D9",
                                '&:hover': {
                                    backgroundColor: checked ? "#00CC5B" : "#D9D9D9",
                                },
                                '&:active': {
                                    backgroundColor: checked ? "#00CC5B" : "#D9D9D9",
                                },
                                boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
                            }}
                        >{checked ? "+" : "-"}
                        </Button>
                    </Box>
                </Box>
            </Box>
            {/* ボタンエリア */}
            <Box sx={{ padding: idList.counter_type.toString() === '1' ?  "30px 2px" : "50px 2px" }}>
                <Box sx={{ width: '100%'}}>
                {
                    countList?.map((rowData: any, index: number) => (
                        <Box sx={{ width: '100%', display: 'flex' }} key={index}>
                            {
                                rowData.map((data: any, keyIndex: number) => (
                                    <Box sx={{ width: '100%', display: 'flex' }} key={'rowData-'+keyIndex}>
                                        {
                                            data.type === 'icon' &&
                                            <Box sx={{ width: '110px', maxWidth: '110px' }}>
                                            <img src={set_icon()} style={{ width: '90px', maxWidth: '90px', marginLeft: '14px' }} />
                                            </Box>
                                        }
                                        {
                                            data.type.includes('landscape-data') &&
                                            <Box sx={{ width: intersectionWidth, maxWidth: intersectionWidth }}>
                                                {
                                                    data.row.map((landData: any, rowIndex: number) => (
                                                        <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: idList.counter_type.toString() ===  "1" ? '0': data.type.includes('top') ? '40px' : '0' }} key={'landscape-data-'+rowIndex}>
                                                        {landData.map((line: any) => (
                                                            <Box
                                                                key={'landscape-data-key-'+line.key}
                                                                sx={{
                                                                    margin: '2px',
                                                                    width: boxWidth, maxWidth: boxWidth,
                                                                    textAlign: 'center', padding: '8px 0',
                                                                    border: line.type === 'car' ? '2px solid blue' : '2px solid green',
                                                                    borderRadius: '8px',
                                                                    backgroundColor: line.bgColor,
                                                                }}
                                                                onClick={(event) => setCount(line.key, line)}
                                                                >{line.val}
                                                            </Box>
                                                        ))}
                                                        </Box>
                                                    ))
                                                }
                                            </Box>
                                        }
                                        {
                                            data.type.includes('portrait-data') &&
                                            <Box sx={{ width: '110px', maxWidth: '110px' }}>
                                                {
                                                    data.row.map((landData: any, rowIndex: number) => (
                                                        <Box sx={{ display: 'flex', justifyContent: idList.counter_type.toString() ===  "1" ? 'center': data.type.includes('left') ? 'end' : 'start' }} key={'portrait-data-'+rowIndex}>
                                                        {landData.map((line: any) => (
                                                            <Box
                                                                key={'portrait-data-key'+line.key}
                                                                sx={{ margin: '2px',
                                                                    width: portraitBoxWidth, maxWidth: portraitBoxWidth,
                                                                    textAlign: 'center', padding: '8px 0',
                                                                    border: line.type === 'car' ? '2px solid blue' : '2px solid green',
                                                                    borderRadius: '8px',
                                                                    backgroundColor: line.bgColor,
                                                                }}
                                                                onClick={(event) => setCount(line.key, line)}
                                                                >{line.val}
                                                            </Box>
                                                        ))}
                                                        </Box>
                                                    ))
                                                }
                                            </Box>
                                        }
                                        {
                                            data.type === 'intersection' &&
                                            <Box sx={{ width: intersectionWidth, maxWidth: intersectionWidth }}>
                                                <img src={set_intersection()} style={{ width: intersectionWidth, maxWidth: intersectionWidth }} />
                                            </Box>
                                        }
                                        {
                                            data.type === 'blank' &&
                                            <Box sx={{ width: '90px', maxWidth: '90px' }}></Box>
                                        }
                                        {
                                            data.type === "color" &&
                                            <Box sx={{ margin: '10px' }}>
                                                <Box sx={{ display: 'flex', margin: '2px 0' }}>
                                                    <Box sx={{ width: '20px', height: '20px', margin: '0 2px', borderRadius: '8px', backgroundColor: COMMON, border: '2px solid blue' }}></Box>共通
                                                </Box>
                                                <Box sx={{ display: 'flex', margin: '2px 0' }}>
                                                    <Box sx={{ width: '20px', height: '20px', margin: '0 2px', borderRadius: '8px', backgroundColor: UNCOMMON, border: '2px solid blue' }}></Box>非共通
                                                </Box>
                                                <Box sx={{ display: 'flex', margin: '2px 0' }}>
                                                    <Box sx={{ width: '20px', height: '20px', margin: '0 2px', borderRadius: '8px', backgroundColor: UNRELATED, border: '2px solid blue' }}></Box>無関係
                                                </Box>
                                            </Box>
                                        }
                                        {
                                            data.type === "common_uncommon" &&
                                            <Box sx={{ margin: "10px", textAlign: "center" }}>
                                                {
                                                    ["0", "1"].includes(idList.counter_type.toString()) &&
                                                    <Box sx={{ 
                                                        display: data.car ? 'flex' : 'none',
                                                        backgroundColor: '#99ddff',
                                                        border: '1px solid #0000FF',
                                                        margin: '4px 0',
                                                        padding: '4px',
                                                        fontWeight: "bold",
                                                    }}>
                                                        <Box>{"車："}</Box>
                                                        <Box sx={{
                                                            width: '60px',
                                                            minWidth: '60px',
                                                            maxWidth: '60px',
                                                        }}>{showCommonRatio("car")}</Box>
                                                    </Box>
                                                }
                                                {
                                                    ["1", "2"].includes(idList.counter_type.toString()) &&
                                                    <Box sx={{
                                                        display: data.people ? 'flex' : 'none',
                                                        backgroundColor: '#ccffcc',
                                                        border: '1px solid #00cc00',
                                                        margin: '4px 0',
                                                        padding: '4px',
                                                        fontWeight: "bold",
                                                    }}>
                                                        <Box>{"人："}</Box>
                                                        <Box sx={{
                                                            width: '60px',
                                                            minWidth: '60px',
                                                            maxWidth: '60px',
                                                        }}>{showCommonRatio("people")}</Box>
                                                    </Box>
                                                }
                                            </Box>
                                        }
                                    </Box>
                                ))
                            }
                        </Box>
                    ))
                }
                </Box>
            </Box>
        </div>
    );
        
}


export default Impact;